import React from 'react';
import WhatsAppButton from './WhatsAppButton'; 
import '../styles/CostSection.css';

const CostSection = () => {
  return (
    <div className="cost_flex">
      <img src="./maleta.png" alt="" className='maleta' />
      <div className="cost-section-container">
        <div className="cost-section-left">
          <img src="./windows-view.png" alt="Windows View" className="cost-image" />
        </div>
        <div className="cost-section-right">
          <h1 className="cost-title">¿Por qué son tan bajos los costos?</h1>
          <p className="cost-description">
            Dependiendo del viaje que desees realizar, contamos con acceso a plataformas que ofrecen paquetes vacacionales que no incluyen los precios elevados de publicidad que pagan las plataformas comerciales.
            <br /><br />
            Lo único que tienes que hacer, es entrar en contacto con tu agente especializado en escapesdelujo.com y cotizar tu próxima viaje.
          </p>
          <WhatsAppButton 
            text="Hola, quiero cotizar mi próximo viaje" 
            buttonText={<button className="quote-button">¡COTIZA TU PRÓXIMO VIAJE AHORA!</button>} 
            className="quote-button-link" 
          />
        </div>
      </div>
    </div>
  );
}

export default CostSection;
