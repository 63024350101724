import React from "react";
import "../styles/Programs.css";

const programs = [
  {
    image: "./miami.png",
    title: "Programa Vacacional a Miami",
    description: "2 noches / 3 días",
    price: "desde tan solo $199 USD <br/> para toda una familia o pareja",
  },
  {
    image: "./orlando.png",
    title: "Programa Vacacional a Orlando",
    description: "3 noches / 4 días",
    price: "desde tan solo $199 USD <br/> para toda una familia o pareja",
  },
  {
    image: "./cancun.png",
    title: "Programa Vacacional a Cancún",
    description: "3 noches / 4 días",
    price: "desde tan solo $199 USD <br/> para toda una familia o pareja",
  },
];

const Programs = () => {
  return (
    <div className="programs-container">
      {programs.map((program, index) => (
        <div key={index} className="program-card">
          <img
            src={program.image}
            alt={program.title}
            className="program-card-image"
          />
          <h2 className="program-card-title">{program.title}</h2>
          <p className="program-card-description">{program.description}</p>
          <p
            className="program-card-price"
            dangerouslySetInnerHTML={{ __html: program.price }}
          ></p>
        </div>
      ))}
    </div>
  );
};

export default Programs;
