import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import '../styles/Videos.css';

const Videos = () => {
  const videoSources = ['./video1.mp4', './video2.mp4', './video3.mp4'];

  return (
    <div className="videos-container">
      <div className="videos-grid">
        {videoSources.map((src, index) => (
          <div key={index} className="video-wrapper">
            <video
              src={src}
              controls
              className="video-player"
              poster="./place.png"
            ></video>
          </div>
        ))}
      </div>
      <WhatsAppButton 
        text="Hola, quiero cotizar mi próximo viaje" 
        buttonText={<button className="quote-button">¡COTIZA TU PRÓXIMO VIAJE AHORA!</button>} 
        className="quote-button-link" 
      />
    </div>
  );
}

export default Videos;
