import React from 'react';
import '../styles/DreamDestinations.css';

const DreamDestinations = () => {
  return (
    <div className="dream-container">
      <div className="dream-text">
        <p className="dream-description">
          En <strong>escapesdelujo.com</strong>, no solo planificamos viajes; creamos experiencias exclusivas adaptadas a tus sueños.
        </p>
        <p className="dream-description">
          Queremos que tu aventura sea inolvidable sin importar tu presupuesto.
        </p>
      </div>
      <div className="dream-image-container">
        <img src="./destinos-de-ensueno.png" alt="Destinos de Ensueño" className="dream-image" />
      </div>
    </div>
  );
}

export default DreamDestinations;
