import React from 'react';
import '../styles/Destinations.css';

const destinations = [
  { image: './paris.png', alt: 'Paris' },
  { image: './tokyo.png', alt: 'Tokyo' },
  { image: './rome.png', alt: 'Rome' },
  { image: './cancun.png', alt: 'Cancun' }
];

const Destinations = () => {
  return (
    <div className="destinations-container">
      <h1 className="destinations-title">
        ¿Sueñas con explorar destinos icónicos como París, Roma, Grecia, Tokio y más, alojándote en los mejores hoteles a precios imbatibles en cualquier parte del mundo?
      </h1>
      <p className="destinations-description">
        <strong>escapesdelujo.com</strong> es la única agencia de viajes digital que te proporciona las mejores experiencias de viaje a precios mucho más bajos que los que podrás encontrar en internet.
      </p>
      <div className="destinations-images">
        {destinations.map((destination, index) => (
          <img key={index} src={destination.image} alt={destination.alt} className="destination-image" />
        ))}
      </div>
    </div>
  );
}

export default Destinations;
