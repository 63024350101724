import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import '../styles/Autoridad.css';

const Autoridad = () => {
  return (
    <div className="autoridad-container">
      <WhatsAppButton 
        text="Hola, quiero cotizar mi próximo viaje" 
        buttonText={<button className="autoridad-button">¡COTIZA TU PRÓXIMO VIAJE AHORA!</button>} 
        className="autoridad-button-link" 
      />
      <div className='footer_final'>
        <img src="logo.png" alt="Logo" />
      </div>
    </div>
  );
}

export default Autoridad;
