import React from 'react';
import '../styles/CustomAdventure.css';

const CustomAdventure = () => {
  return (
    <div className="adventure-container">
      <h1 className="adventure-title">
        <span className='color_title'>Arma tu propia</span> <br /> aventura
      </h1>
      <div className="adventure-content">
        <div className="adventure-text">
          <p>
            En <strong>escapesdelujo.com</strong> creemos en la personalización. Te ayudamos a crear tu propio paquete de viaje a medida, con opciones que se adaptan a tus gustos y necesidades. Desde el vuelo de tu avión, renta de autos, traslados y asesoría de visas, estamos aquí para hacer que tu viaje sea inolvidable en todos los aspectos.
          </p>
        </div>
        <div className="adventure-images">
          <div className="image-wrapper">
            <img src="./sup1.png" alt="Suplementary 1" className="sup-image sup-image-1" />
            <img src="./sup2.png" alt="Suplementary 2" className="sup-image sup-image-2" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomAdventure;
