import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { useUser } from "../context/UserProvider";
import Hero from "./Hero";
import Programs from "./Programs";
import Videos from "./Videos";
import Destinations from "./Destinations";
import CostSection from "./CostSection";
import DreamDestinations from "./DreamDestinations";
import QuoteSection from "./QuoteSection";
import TestimonialVideo from "./TestimonialVideo";
import CustomAdventure from "./CustomAdventure";
import Autoridad from "./Autoridad";
import { defaultUser } from "../context/AppConstants";
import ExpertProfile from "./ExpertProfile";
const Wrapper = () => {
  const { usuario } = useParams();
  const { user, setUser } = useUser();

  useEffect(() => {
    const fetchUserData = async () => {
      console.log(usuario);
      try {
        const response = await fetch(
          `https://bombazoo.com/api/visa/clave/${usuario}`
        );
        const data = await response.json();
        if (data.ok) {
          const user = data.visa;
          console.log(user);
          setUser({
            ...user,
            usuario: user.clave,
            imagen: user.url_imagen,
          });
        } else {
          setUser(defaultUser);
        }
      } catch (error) {
        setUser(defaultUser);
        console.error("Error fetching user data:", error);
      }
    };

    if (!user || user.usuario !== usuario) {
      fetchUserData();
    }
  }, [usuario]);
  return (
    <div className="App">
      <Hero />
      <Programs />
      <Videos />
      <Destinations />
      <CostSection />
      <DreamDestinations />
      <QuoteSection />
      <TestimonialVideo />
      <CustomAdventure />
      <ExpertProfile />
      <Autoridad />
    </div>
  );
};

export default Wrapper;
