import React from 'react';
import '../styles/TestimonialVideo.css';

const TestimonialVideo = () => {
  return (
    <div className="testimonial-container">
      <h1 className="testimonial-title">Mira algunos testimonios de algunas familias que han viajado con nuestros paquetes:</h1>
      <div className="video-wrapper">
        <video
          src="./video2.mp4"
          controls
          className="testimonial-video"
          poster="./place.png"

        ></video>
      </div>
    </div>
  );
}

export default TestimonialVideo;
