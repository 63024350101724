import React from 'react';
import WhatsAppButton from './WhatsAppButton'; 
import '../styles/QuoteSection.css';

const QuoteSection = () => {
  return (
    <div className="quote-section-container">
      <p className="quote-text">
        Desde las emocionantes atracciones de Orlando hasta las playas paradisíacas de Cancún, en escapesdelujo.com te esperan destinos que te dejarán sin aliento. Descubre la elegancia y el encanto de nuestros paquetes y vive experiencias únicas que perdurarán toda la vida.
      </p>
      <WhatsAppButton 
        text="Hola, quiero cotizar mi próximo viaje" 
        buttonText={<button className="quote-button">¡COTIZA TU PRÓXIMO VIAJE AHORA!</button>} 
        className="quote-button-link" 
      />
    </div>
  );
}

export default QuoteSection;
